import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import moment from 'moment';
import { ThreeDots } from 'react-bootstrap-icons';
import ValidationIconWrapper from './validationIconWrapper';

export function TextCell({ value, className, style }) {
  let resultClassName = 'cell-input';
  if (className) {
    resultClassName += ` ${className}`;
  }
  return (
    <ValidationIconWrapper>
      <Form.Control type="text" disabled readOnly value={value} className={resultClassName} style={style} />
    </ValidationIconWrapper>
  );
}

export function NumberCell({ value, style }) {
  return (
    <ValidationIconWrapper>
      <Form.Control type="number" disabled readOnly value={value} style={style} className="cell-input text-end" />
    </ValidationIconWrapper>
  );
}

export function MinuteCell({ value }) {
  return (
    <InputGroup className="flex-nowrap">
      <NumberCell value={value} />
      <InputGroup.Text>min</InputGroup.Text>
    </InputGroup>
  );
}

export function IndexCell({ row: { index } }) {
  return <span>{index + 1}</span>;
}

export function SimpleButtonCell(props) {
  return (
    <Button {...props} className="w-100" tabIndex={-1} />
  );
}

export function ButtonCell(props) {
  const { column: { buttonProps, buttonContent }, disabled } = props;
  return (
    <Button {...buttonProps} onClick={() => buttonProps.onClick(props)} className="w-100" tabIndex={-1} disabled={disabled}>
      {buttonContent}
    </Button>
  );
}

export function EditableCell(props) {
  const {
    value: initialValue,
    row: { index },
    column: { id, inputProps, onKeyDown },
    updateMyData, // This is a custom function that we supplied to our table instance
    disabled,
  } = props;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onInputKeyDown = (e) => {
    if (typeof onKeyDown === 'function') {
      onKeyDown(e, props);
    }
    if (inputProps && inputProps.min >= 0 && e.code === 'Minus') {
      e.preventDefault();
    }
  };

  let className = 'cell-input';
  if (inputProps && inputProps.type === 'number') {
    className += ' text-end';
  }

  return (
    <ValidationIconWrapper>
      <Form.Control
        className={className}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...inputProps}
        onKeyDown={onInputKeyDown}
        disabled={disabled}
      />
    </ValidationIconWrapper>
  );
}

export function WithButton(BaseCell, onButtonClick) {
  return function DecoratedComponent(props) {
    const { column, disabled } = props;
    const onClick = () => {
      if (typeof onButtonClick === 'function') {
        onButtonClick(props);
      }
    };
    return (
      <InputGroup className="flex-nowrap">
        <BaseCell {...props} />
        <Button {...column.buttonProps} tabIndex={-1} onClick={onClick} variant="secondary" disabled={disabled}>
          <ThreeDots />
        </Button>
      </InputGroup>
    );
  };
}

const convertValue = (val) => ({
  min: Math.floor(val / 60),
  sec: val % 60,
});

export function DurationCell({
  value, onChange,
  disabled, readOnly,
  prefixes, suffixes,
  minuteProps, secondProps,
}) {
  // We need to keep and update the state of the cell normally
  const converted = convertValue(value);
  const [minValue, setMinValue] = useState(converted.min);
  const [secValue, setSecValue] = useState(converted.sec);

  const onMinChange = (e) => {
    setMinValue(parseInt(e.target.value, 10));
  };

  const onSecChange = (e) => {
    const newVal = parseInt(e.target.value, 10);
    // let newMin = minValue;
    // while (newVal < 0) {
    //   newVal = 60 + newVal;
    //   newMin -= 1;
    // }
    // if (newVal >= 60) {
    //   newMin += Math.floor(newVal / 60);
    //   newVal %= 60;
    // }

    // if (newMin < 0) {
    //   newMin = 0;
    //   newVal = 0;
    // }

    if (newVal < 0 || newVal > 59) {
      return;
    }

    setSecValue(newVal);
    // setMinValue(newMin);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (typeof onChange === 'function') {
      const seconds = parseInt(minValue, 10) * 60 + parseInt(secValue, 10);
      onChange(seconds);
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    const conv = convertValue(value);
    setMinValue(conv.min);
    setSecValue(conv.sec);
  }, [value]);

  const minutesWidth = disabled && readOnly ? '4em' : '5em';
  const secondsWidth = disabled && readOnly ? '3em' : '4em';

  const minuteStyle = { ...(minuteProps ? minuteProps.style : {}), flex: '1 1 0', minWidth: minutesWidth };
  const secondStyle = { ...(secondProps ? secondProps.style : {}), flex: '0', minWidth: secondsWidth };

  return (
    <InputGroup hasValidation className="flex-nowrap">
      {prefixes}
      <ValidationIconWrapper>
        <Form.Control
          className="text-end"
          type={disabled && readOnly ? 'text' : 'number'}
          min={0}
          disabled={disabled}
          readOnly={readOnly}
          {...minuteProps}
          style={minuteStyle}
          value={minValue}
          onChange={onMinChange}
          onBlur={onBlur}
        />
      </ValidationIconWrapper>
      <InputGroup.Text>:</InputGroup.Text>
      <ValidationIconWrapper>
        <Form.Control
          className="text-end"
          type={disabled && readOnly ? 'text' : 'number'}
          min={0}
          max={59}
          disabled={disabled}
          readOnly={readOnly}
          {...secondProps}
          style={secondStyle}
          value={secValue}
          onChange={onSecChange}
          onBlur={onBlur}
        />
      </ValidationIconWrapper>
      {suffixes}
    </InputGroup>
  );
}

export function ReadonlyDurationCell({ value }) {
  return <DurationCell value={value} readOnly disabled />;
}

export function EditableCellDuration(props) {
  const {
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    minuteProps,
    secondProps,
    disabled,
    readOnly,
  } = props;

  return (
    <DurationCell
      value={initialValue}
      onChange={(newValue) => updateMyData(index, id, newValue)}
      disabled={disabled}
      readOnly={readOnly}
      minuteProps={minuteProps}
      secondProps={secondProps}
    />
  );
}

// In case of older date strings cut off the timezone data.
function convertDateTimeValue(isoString) {
  let result = isoString;
  if (result.includes('Z')) {
    result = moment(result).utcOffset(0, true).toISOString();
    result = result.slice(0, result.indexOf('T') + 9);
  }
  return result;
}

export function EditableCellDateTime(props) {
  const {
    value: initialValue,
    row: { index },
    column: { id, inputProps, onKeyDown },
    updateMyData, // This is a custom function that we supplied to our table instance
    disabled,
  } = props;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(convertDateTimeValue(initialValue));

  const onChange = (e) => {
    const val = e.target.value;
    setValue(val);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(convertDateTimeValue(initialValue));
  }, [initialValue]);

  const onInputKeyDown = (e) => {
    if (typeof onKeyDown === 'function') {
      onKeyDown(e, props);
    }
  };

  return (
    <ValidationIconWrapper>
      <Form.Control
        type="datetime-local"
        className="cell-input"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...inputProps}
        onKeyDown={onInputKeyDown}
        disabled={disabled}
      />
    </ValidationIconWrapper>
  );
}

export function DropdownCell({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  required,
  children,
  onKeyDown,
  disabled,
  style,
}) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <ValidationIconWrapper>
      <Form.Select className="cell-input" style={style} value={value} onChange={onChange} onBlur={onBlur} required={required} onKeyDown={onKeyDown} disabled={disabled}>
        {children}
      </Form.Select>
    </ValidationIconWrapper>
  );
}
