import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Stack from 'react-bootstrap/Stack';
import {
  XCircleFill,
  CaretDown,
  CaretRight,
  GripVertical,
  ThreeDots,
  ArrowRight,
  Link45deg,
  WrenchAdjustable,
} from 'react-bootstrap-icons';
import { useTable } from 'react-table';
import { v4 as uuidv4 } from 'uuid';
import { frequencies } from '../../../helper/frequency';
import { getCalculated } from '../../../helper/activities';
import {
  DropdownCell,
  DurationCell,
  EditableCell,
  EditableCellDuration,
  IndexCell,
  ReadonlyDurationCell,
  SimpleButtonCell,
  TextCell,
  WithButton,
} from '../../../helper/table';
import { roundPercentage } from '../../../helper/format';
import EditVolumeLink from './edit-volume-link';

const defaultValues = {
  name: '',
  description: '',
  volumeLink: null,
  volume: 1,
  volumeNotes: '',
  unit: '',
  frequency: frequencies[0].value,
  estimatedSeconds: 60,
  expectedSeconds: null,
};

const defaultGroupValues = {
  name: 'new group',
  activities: [],
};

const DRAGDROP_CONTEXT = {
  GROUP: 'group',
  ROW: 'row',
};

function ActivityCell(props) {
  return <EditableButtonCell {...props} />;
}

function VolumeCell(props) {
  const {
    row,
    disabled,
    startEditingVolumeLink,
    startEditingVolumeNotes,
  } = props;
  const volumeLinked = !!row.original?.volumeLink;

  return (
    <InputGroup className="flex-nowrap">
      <Button tabIndex={-1} onClick={() => startEditingVolumeLink(row)} variant={volumeLinked ? 'secondary' : 'outline-secondary'} disabled={disabled}>
        {volumeLinked
          ? <Link45deg />
          : <WrenchAdjustable />}
      </Button>
      <EditableCell {...props} disabled={volumeLinked || disabled} />
      <Button tabIndex={-1} onClick={() => startEditingVolumeNotes(row)} variant="secondary" disabled={disabled}>
        <ThreeDots />
      </Button>
    </InputGroup>
  );
}

function ActivityFooter({ addNewActivity, addNewActivityGroup }) {
  return (
    <Stack direction="horizontal" gap={3}>
      <Button onClick={() => addNewActivity()} className="w-100">Add activity</Button>
      <Button onClick={() => addNewActivityGroup()} className="w-100">Add grouping</Button>
    </Stack>
  );
}

function FrequenciesCell(props) {
  return (
    <DropdownCell required {...props} style={{ maxWidth: '150px' }}>
      <option>Please select a frequency.</option>
      {frequencies.map((f) => <option key={f.value} value={f.value}>{f.display}</option>)}
    </DropdownCell>
  );
}

function WorkloadCell(props) {
  return (
    <InputGroup className="flex-nowrap">
      <TextCell className="text-end" {...props} disabled style={{ maxWidth: '100px' }} />
      <InputGroup.Text>%</InputGroup.Text>
    </InputGroup>
  );
}

function EstimateCell(props) {
  const { addNewActivity, row: { index }, rows: { length } } = props;

  function lastCellKeyDown(e) {
    if (e.key === 'Tab' && index === length - 1) {
      addNewActivity();
      e.preventDefault();
    }
  }

  return (
    <EditableCellDuration
      {...props}
      secondProps={{ onKeyDown: lastCellKeyDown }}
    />
  );
}

function WeeklyEstimateFooter({ activityData }) {
  const total = activityData.reduce((sum, row) => row.weeklyEstimatedSeconds + sum, 0);

  return <DurationCell value={total} readOnly disabled />;
}

function RemoveActivityButtonCell({ row: { original: { id } }, askRemoveActivity }) {
  return (
    <SimpleButtonCell variant="danger" onClick={() => askRemoveActivity(id)}>
      <XCircleFill />
    </SimpleButtonCell>
  );
}

const EditableButtonCell = (props) => WithButton(EditableCell, props.column.onButtonClick)(props);

function GroupHandle({ isExpanded, onClick }) {
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick} onKeyPress={onClick} role="switch" aria-checked={isExpanded} tabIndex={0}>
      {isExpanded ? <CaretDown /> : <CaretRight />}
    </div>
  );
}

function DragHandle({ dropping }) {
  return (
    <div style={{ cursor: 'grabbing' }}>
      {dropping ? <ArrowRight color="#0289CD" /> : <GripVertical />}
    </div>
  );
}

const includeDndThreshold = false;
function DraggableGroup({
  dndIndex, group, children, updateGroup, removeGroup, moveDragDrop,
}) {
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [groupName, setGroupName] = useState(group.name);
  const [editing, setEditing] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const onChange = (e) => setGroupName(e.target.value);
  const onBlur = () => {
    updateGroup(group.id, 'name', groupName);
    setEditing(false);
  };

  const [{ isOver }, drop] = useDrop({
    accept: [DRAGDROP_CONTEXT.GROUP, DRAGDROP_CONTEXT.ROW],
    canDrop(item, monitor) {
      if (!dropRef.current) {
        return false;
      }

      const dragIndex = item.index;
      const hoverIndex = dndIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return true;
      }

      if (collapsed && monitor.getItemType() === DRAGDROP_CONTEXT.ROW) {
        setCollapsed(false);
      }

      if (includeDndThreshold) {
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return false;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return false;
        }
      }

      return true;
    },
    drop(item) {
      if (!dropRef.current) {
        return;
      }

      const hoverIndex = dndIndex;
      // Time to actually perform the action
      moveDragDrop({ groupId: item.groupId, activityId: item.activityId }, { groupId: group.id });

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = item.activityId ? hoverIndex + 1 : hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAGDROP_CONTEXT.GROUP,
    item: { index: dndIndex, groupId: group.id },
    isDragging: (monitor) => group.id === monitor.getItem().groupId,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      // direction: monitor.getDifferenceFromInitialOffset()?.y || 0,
    }),
  });

  const opacity = isDragging ? 0 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  const onClick = (e) => {
    setCollapsed(!collapsed);
    e.preventDefault();
  };

  return (
    <>
      <tr ref={dropRef} style={{ opacity }}>
        <td ref={dragRef} style={{ verticalAlign: 'middle' }}><DragHandle dropping={isOver} /></td>
        <td style={{ verticalAlign: 'middle' }}>
          <GroupHandle isExpanded={!collapsed} onClick={onClick} />
        </td>
        <th colSpan={7} className="align-bottom">
          {editing
            ? (<Form.Control value={groupName} type="text" onChange={onChange} onBlur={onBlur} />)
            : (
              <>
                {group.name}
                <Button tabIndex={-1} onClick={() => setEditing(true)} variant="secondary" style={{ marginLeft: '1em' }}>
                  <ThreeDots />
                </Button>
              </>
            )}
        </th>
        <td>
          <Button variant="danger" onClick={() => removeGroup(group.id)} className="w-100" tabIndex={-1}>
            <XCircleFill />
          </Button>
        </td>
      </tr>
      {children({ collapsed, isParentDragging: isDragging })}
    </>
  );
}

function DraggableRow({
  row, dndIndex, moveDragDrop, isParentDragging, collapsed,
}) {
  const dropRef = useRef(null);
  const dragRef = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: DRAGDROP_CONTEXT.ROW,
    canDrop(item, monitor) {
      if (!dropRef.current) {
        return false;
      }

      // if (row.canExpand) {
      //   row = row.subRows[0];
      // }

      const dragIndex = item.index;
      const hoverIndex = dndIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return false;
      }

      if (includeDndThreshold) {
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return false;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return false;
        }
      }

      return true;
    },
    drop(item) {
      if (!dropRef.current) {
        return;
      }

      const hoverIndex = dndIndex;
      // Time to actually perform the action
      moveDragDrop(
        { activityId: item.activityId },
        { groupId: row.original.groupId, activityId: row.original.id },
      );
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      // direction: monitor.getDifferenceFromInitialOffset()?.y || 0,
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DRAGDROP_CONTEXT.ROW,
    item: { index: dndIndex, activityId: row.original.id },
    isDragging: (monitor) => row.original.id === monitor.getItem().activityId,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isParentDragging || isDragging ? 0 : 1;
  const visibility = collapsed ? 'collapse' : 'visible';

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity, visibility }}>
      <td ref={dragRef} style={{ verticalAlign: 'middle' }}><DragHandle dropping={isOver} /></td>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps({
          className: cell.column.className,
          style: { ...cell.column.style, ...cell.column.rowStyle },
        })}
        >
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
}

function RemoveActivityWarning({ show, onHide, onConfirm }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Removing an activity will also remove the observations,
          instructions and expectations that refer to this activity.
          Any volume links referring to this activity will be severed.
        </p>
        <p>Continue removing?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function RemoveGroupWarning({ show, onHide, onConfirm }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Removing a group and its activities will also remove
          the observations, instructions and expectations that refer to them.
          Any volume links referring to these activities will be severed.
        </p>
        <p>Continue removing?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ActivityGroupsTable(props) {
  const { value, onChange } = props;

  const wasNewlyAdded = useRef(false);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const [activityData, setActivityData] = useState([]);
  const [totalWeeklySeconds, setTotalWeeklySeconds] = useState(0);
  const [modalProps, setModalProps] = useState(null);
  const [modalValue, setModalValue] = useState(null);
  const [editingVolumeLink, setEditingVolumeLink] = useState(null);

  const [removingActivityId, setRemovingActivityId] = useState(null);
  const [removingGroupId, setRemovingGroupId] = useState(null);

  const scrollDummy = useRef(null);
  const processingDnd = useRef(false);

  const activities = useMemo(() => value
    .reduce((result, currentGroup) => result
      .concat(currentGroup.activities.map((a, activityIndex) => ({
        ...a,
        groupId: currentGroup.id,
        relativeIndex: activityIndex,
      }))), []), [value]);

  useEffect(() => {
    setActivityData(activities.map((a) => getCalculated(a)));
    if (wasNewlyAdded.current) {
      scrollDummy.current.scrollIntoView();
      wasNewlyAdded.current = false;
    }
    processingDnd.current = false;
  }, [activities]);

  useEffect(() => {
    if (activityData) {
      setTotalWeeklySeconds(
        activityData.reduce((total, next) => total + (next.weeklyEstimatedSeconds || 0), 0),
      );
    }
  }, [activityData]);

  const updateGroup = useCallback((groupId, prop, newValue) => {
    emitChange(value.map((group) => {
      if (group.id === groupId) {
        return { ...group, [prop]: newValue };
      }
      return group;
    }));
  }, [value, emitChange]);

  const updateField = useCallback((state, rowIndex, columnId, newValue) => {
    const row = activities[rowIndex];

    return state.map((group) => {
      if (group.id === row.groupId) {
        return {
          ...group,
          activities: group.activities.map((activity) => {
            if (activity.id === row.id) {
              return {
                ...activity,
                [columnId]: newValue,
              };
            }
            return activity;
          }),
        };
      }
      return group;
    });
  }, [activities]);

  const updateFieldCascading = useCallback((state, rowIndex, columnId, newValue) => {
    let result = updateField(state, rowIndex, columnId, newValue);

    const row = activities[rowIndex];

    if (columnId === 'volumeLink') {
      if (newValue) {
        const parent = activities.find((a) => a.id === newValue.id);
        result = updateFieldCascading(result, rowIndex, 'volume', parent.volume * newValue.ratio);
      }
    }

    if (columnId === 'volume') {
      const dependents = activities.filter((a) => a.volumeLink?.id === row.id);
      dependents.forEach((d) => {
        result = updateFieldCascading(result, activities.indexOf(d), 'volume', d.volumeLink.ratio * newValue);
      });
    }

    return result;
  }, [updateField, activities]);

  const updateMyData = useCallback((rowIndex, columnId, newValue) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true);
    const result = updateFieldCascading(value, rowIndex, columnId, newValue);

    emitChange(result);
  }, [value, emitChange, updateFieldCascading]);

  const getRowId = useCallback((row) => row.id, []);

  const addNewActivity = () => {
    if (value.length === 0) {
      value.push({ ...defaultGroupValues, id: uuidv4() });
    }
    const relevantGroup = value[value.length - 1];
    emitChange([
      ...value.slice(0, -1),
      {
        ...relevantGroup,
        activities: [...relevantGroup.activities, { ...defaultValues, id: uuidv4() }],
      },
    ]);
    wasNewlyAdded.current = true;
  };

  const addNewActivityGroup = () => {
    emitChange([...value, { ...defaultGroupValues, id: uuidv4() }]);
    wasNewlyAdded.current = true;
  };

  const askRemoveActivity = (id) => {
    setRemovingActivityId(id);
  };

  const breakLinks = (state, activityIds) => state.map(((ag) => ({
    ...ag,
    activities: ag.activities.map((a) => {
      const result = { ...a };
      if (activityIds.includes(a.volumeLink?.id)) {
        result.volumeLink = null;
      }
      return result;
    }),
  })));

  const removeActivity = (id) => {
    const result = breakLinks(value, [id]).map((ag) => ({
      ...ag,
      activities: ag.activities.filter((a) => a.id !== id).map((a) => {
        const activityResult = a;
        if (activityResult.volumeLink?.id === id) {
          activityResult.volumeLink = null;
        }
        return activityResult;
      }),
    }));
    emitChange(result);
    setRemovingActivityId(null);
  };

  const askRemoveActivityGroup = (id) => {
    setRemovingGroupId(id);
  };

  const removeActivityGroup = (id) => {
    const group = value.find((ag) => ag.id === id);
    const activityIds = group.activities.map((a) => a.id);
    const result = breakLinks(value, activityIds)
      .filter((ag) => ag.id !== id);
    emitChange(result);
    setRemovingGroupId(null);
  };

  const moveDragDrop = (dragItem, hoverItem) => {
    if (processingDnd.current) {
      return;
    }
    processingDnd.current = true;
    let result = value;
    if (dragItem.activityId) {
      const dragActivity = result
        .reduce((existingDragItem, current) => existingDragItem
          || current.activities.find((a) => a.id === dragItem.activityId), null);

      result = result.map((group) => {
        const newGroup = { ...group };

        const groupInsertIndex = newGroup.id === hoverItem.groupId ? 0 : -1;

        const insertIndex = hoverItem.activityId
          ? newGroup.activities.findIndex((a) => a.id === hoverItem.activityId)
          : groupInsertIndex;
        newGroup.activities = newGroup.activities.filter((a) => a.id !== dragItem.activityId);

        if (insertIndex !== -1) {
          newGroup.activities = [
            ...newGroup.activities.slice(0, insertIndex),
            dragActivity,
            ...newGroup.activities.slice(insertIndex),
          ];
        }

        return newGroup;
      });
    } else {
      const insertIndex = result.findIndex((group) => group.id === hoverItem.groupId);
      const dragGroup = result.find((group) => group.id === dragItem.groupId);
      result = result.filter((group) => group.id !== dragItem.groupId);

      if (insertIndex !== -1) {
        result = [...result.slice(0, insertIndex), dragGroup, ...result.slice(insertIndex)];
      }
    }
    emitChange(result);
  };

  const editModally = (name, title, propName, rowIndex, initialValue) => {
    setModalProps({
      title,
      name,
      propName,
      rowIndex,
    });
    setModalValue(initialValue);
  };

  const onHideModal = () => {
    if (!modalProps) {
      return;
    }
    updateMyData(modalProps.rowIndex, modalProps.propName, modalValue);
    setModalProps(null);
    setModalValue(null);
  };

  const startEditingVolumeLink = (row) => {
    setEditingVolumeLink({
      rowIndex: row.index,
      activity: row.original,
      value: row.original.volumeLink,
    });
  };

  const volumeLinkChanged = (rowIndex, newValue) => {
    setEditingVolumeLink({
      ...editingVolumeLink,
      value: newValue,
    });
    updateMyData(rowIndex, 'volumeLink', newValue);
  };

  const startEditingVolumeNotes = (row) => editModally(
    row.original.name,
    'Volume notes',
    'volumeNotes',
    row.index,
    row.original.volumeNotes,
  );

  // const groups = useMemo(() => value, [value]);

  const columns = useMemo(() => [
    {
      id: 'index',
      Header: '',
      Cell: IndexCell,
      rowStyle: { verticalAlign: 'middle' },
    },
    {
      Header: 'Activity',
      accessor: 'name',
      Cell: ActivityCell,
      inputProps: { required: true, style: { minWidth: '30em' } },
      style: { width: '99%' },
      onButtonClick: ({ row }) => editModally(
        row.original.name,
        'Description',
        'description',
        row.index,
        row.original.description,
      ),
      Footer: ActivityFooter,
    },
    {
      Header: 'Volume',
      accessor: 'volume',
      Cell: VolumeCell,
      inputProps: {
        type: 'number', min: 0, step: 1, style: { maxWidth: '100px' },
      },
      style: { minWidth: '140px' },
    },
    {
      Header: 'Unit',
      accessor: 'unit',
      Cell: EditableCell,
      style: { minWidth: '10em' },
    },
    {
      Header: 'Frequency',
      accessor: 'frequency',
      Cell: FrequenciesCell,
      style: { minWidth: '10em' },
    },
    {
      Header: 'Estimated',
      accessor: 'estimatedSeconds',
      Cell: EstimateCell,
      style: { minWidth: '10em' },
    },
    {
      Header: 'Weekly',
      accessor: (a, i) => activityData[i]?.weeklyEstimatedSeconds || 0,
      Cell: ReadonlyDurationCell,
      style: { minWidth: '10em' },
      Footer: WeeklyEstimateFooter,
    },
    {
      Header: 'Workload',
      accessor: (a, i) => {
        const currentWeeklySecs = activityData[i]?.weeklyEstimatedSeconds || 0;
        const pct = totalWeeklySeconds > 0 ? (100 * currentWeeklySecs) / totalWeeklySeconds : 0;
        return roundPercentage(pct);
      },
      Cell: WorkloadCell,
      style: { minWidth: '8em' },
    },
    {
      Header: '',
      id: 'actions',
      Cell: RemoveActivityButtonCell,
      style: { minWidth: '4em' },
    },
  ], [totalWeeklySeconds, activityData]);

  const data = useMemo(() => activities, [activities]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    data,
    activityData,
    columns,
    getRowId,
    updateMyData,
    addNewActivity,
    addNewActivityGroup,
    askRemoveActivity,
    startEditingVolumeLink,
    startEditingVolumeNotes,
  });

  const getGroupedRows = () => {
    let i = 0;
    return (
      value.map((group) => {
        const relevantRows = rows.filter((r) => r.original.groupId === group.id);
        const groupDndIndex = i;
        i += relevantRows.length + 1;
        return (
          <DraggableGroup
            key={group.id}
            dndIndex={groupDndIndex}
            group={group}
            headerGroups={headerGroups}
            moveDragDrop={moveDragDrop}
            updateGroup={updateGroup}
            removeGroup={askRemoveActivityGroup}
          >
            {(parentProps) => relevantRows.map(
              (row, rowIndex) => {
                const activityDndIndex = groupDndIndex + 1 + rowIndex;
                return prepareRow(row) || (
                  <DraggableRow
                    {...row.getRowProps()}
                    dndIndex={activityDndIndex}
                    row={row}
                    moveDragDrop={moveDragDrop}
                    key={row.id}
                    {...parentProps}
                  />
                );
              },
            )}
          </DraggableGroup>
        );
      })
    );
  };

  return (
    <div className="h-100">
      <RemoveActivityWarning
        show={!!removingActivityId}
        onHide={() => setRemovingActivityId(null)}
        onConfirm={() => removeActivity(removingActivityId)}
      />
      <RemoveGroupWarning
        show={!!removingGroupId}
        onHide={() => setRemovingGroupId(null)}
        onConfirm={() => removeActivityGroup(removingGroupId)}
      />
      <Modal show={!!modalProps} onHide={onHideModal} centered size="lg">
        {!!modalProps && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {modalProps.name}
              {' '}
              {modalProps.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control as="textarea" rows={10} value={modalValue} onChange={(e) => setModalValue(e.target.value)} />
          </Modal.Body>
        </>
        )}
      </Modal>
      <Modal show={!!editingVolumeLink} onHide={() => setEditingVolumeLink(null)} centered size="lg">
        {!!editingVolumeLink && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Link45deg className="me-2" />
                {editingVolumeLink.activity.name}
                {' Volume link'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditVolumeLink
                activities={activities.filter((a) => a.id !== editingVolumeLink.activity.id)}
                value={editingVolumeLink.value}
                onChange={(newValue) => volumeLinkChanged(editingVolumeLink.rowIndex, newValue)}
              />
            </Modal.Body>
          </>
        )}
      </Modal>
      {/* className="overflow-auto" overflowY: "auto" */}
      <Table striped borderless hover {...getTableProps()} style={{ minHeight: '0px' }}>
        <thead className="sticky-top bg-primary">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* Add an extra cell to match row grab handle */}
              <th colSpan={1}> </th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {getGroupedRows()}
          <tr id="scroll-dummy" ref={scrollDummy} />
        </tbody>
        <tfoot className="sticky-bottom bg-secondary">
          {footerGroups.map((group) => (
            <tr {...group.getFooterGroupProps()}>
              {/* Add an extra cell to match row grab handle */}
              <td colSpan={1} />
              {group.headers.map((column) => (
                <td {...column.getFooterProps()} style={column.style}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      </Table>
    </div>
  );
}

export default ActivityGroupsTable;
