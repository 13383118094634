import { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import { PencilFill, PersonFill, XCircleFill } from 'react-bootstrap-icons';
import toastService from '../toast-service';
import WorkloadAPI from '../workload-api';
import AccountPermissions from './account-permissions';
import { ClientsProvider } from '../providers/clients-provider';
import ValidationIconWrapper from '../helper/validationIconWrapper';

function AddModal({
  show, onHide, role, clientId, onAdded,
}) {
  const [newAdmin, setNewAdmin] = useState('');
  const [adding, setAdding] = useState(false);
  const [error, setError] = useState(null);

  const closeModal = () => {
    setNewAdmin('');
    onHide();
  };

  const addUser = (email) => {
    setAdding(true);
    setError(null);
    WorkloadAPI.createAccount(email, role, { clientId }).then(() => {
      toastService.toast({
        type: 'success',
        message: 'The account was created successfully.',
      });
      closeModal();
      onAdded();
    }, (reason) => {
      console.error({ reason });
      setError(reason.message);
    }).then(() => setAdding(false));
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        addUser(newAdmin);
      }}
      >
        <fieldset disabled={adding}>
          <Modal.Header closeButton>
            <Modal.Title>
              Add
              {' '}
              {role}
              {' '}
              account
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <ValidationIconWrapper>
                <Form.Control type="email" value={newAdmin} onChange={(e) => setNewAdmin(e.target.value)} />
              </ValidationIconWrapper>
            </Form.Group>
            {error && (
            <Alert variant="danger">
              <Alert.Heading>Error</Alert.Heading>
              <p>
                We were unable to add this account.
              </p>
            </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            {adding && (
            <div>
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Adding...</span>
              </Spinner>
            </div>
            )}
            <Button variant="secondary" type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  );
}

function Account({
  user, role, deleting, onDeleteClick,
}) {
  const [showModal, setShowModal] = useState(false);

  const email = user.Attributes.find((a) => a.Name === 'email').Value;

  return (
    <tr>
      <td className="align-middle">
        <Stack direction="horizontal" gap={1}>
          <AccountPermissions account={user} show={showModal} onHide={() => setShowModal(false)} />
          <PersonFill className="me-2" />
          <span className="me-auto">{email}</span>
          {role !== 'admin'
            && (
            <Button variant="secondary" onClick={() => setShowModal(true)} disabled={deleting}>
              <PencilFill className="me-2" />
              <span>Permissions</span>
            </Button>
            )}
          <Button variant="danger" onClick={onDeleteClick} disabled={deleting}>
            {deleting
              ? (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    display: 'block', margin: 'auto', height: '1.5rem', width: '1.5rem',
                  }}
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )
              : <XCircleFill />}
          </Button>
        </Stack>
      </td>
    </tr>
  );
}

function Accounts({ authRole, clientId }) {
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [deleting, setDeleting] = useState(null);

  const loadAccounts = useCallback(() => {
    const abortController = new AbortController();
    WorkloadAPI.getAccounts(authRole, clientId, abortController.signal)
      .then((res) => setAccounts(res))
      .catch((reason) => {
        if (reason.name === 'AbortError') {
          throw reason;
        }
        toastService.toast({
          type: 'danger',
          message: `An error occurred while trying to load the ${authRole} accounts.`,
        });
        setError(reason);
        console.error(reason);
      })
      .then(() => setDeleting(null))
      .catch(() => {});
    return () => { abortController.abort(); };
  }, [authRole, clientId]);

  useEffect(() => loadAccounts(), [loadAccounts]);

  const deleteAccount = (username) => {
    setDeleting(username);
    WorkloadAPI.deleteAccount(username).then(() => {
      toastService.toast({
        type: 'success',
        message: 'The account was deleted successfully.',
      });
    }, (reason) => {
      console.error({ reason });
      toastService.toast({
        type: 'danger',
        message: 'An error occurred while trying to delete this account.',
      });
    }).then(() => {
      loadAccounts();
    });
  };

  if (error) {
    return (
      <div className="m-auto">
        An error occurred while trying to load the
        {' '}
        {authRole}
        {' '}
        accounts.
        Would you like to
        <Button
          variant="link"
          onClick={() => {
            setError(null);
            loadAccounts();
          }}
        >
          try again?
        </Button>
      </div>
    );
  }

  if (!accounts) {
    return (
      <div className="m-auto">
        <Spinner animation="grow" role="status" style={{ display: 'block', margin: 'auto' }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <ClientsProvider>
      <AddModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        role={authRole}
        clientId={clientId}
        onAdded={() => loadAccounts()}
      />
      <Table striped borderless hover>
        <tbody>
          {accounts.length ? accounts.map((acc) => (
            <Account
              key={acc.Username}
              user={acc}
              role={authRole}
              deleting={deleting === acc.Username}
              onDeleteClick={() => deleteAccount(acc.Username)}
            />
          )) : (
            <tr>
              <td>No accounts found.</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Button className="align-self-center" style={{ width: '20em' }} onClick={() => setShowAddModal(true)}>Add account</Button>
    </ClientsProvider>
  );
}

export default Accounts;
