import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Stack from 'react-bootstrap/Stack';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  CloudDownload, CloudUpload, FileEarmarkPdf, Stickies, Safe2,
} from 'react-bootstrap-icons';
import WorkloadAPI from '../../workload-api';
import toastService from '../../toast-service';
import ActivityGroupsTable from './activity/activity-groups-table';
import InstructionsTable from './observation-instruction/instructions-table';
import ObservationsTable from './observation/observations-table';
import Loading from '../loading';
import ExpectationsTable from './expectation/expectations-table';
import ParetoTable from './pareto/pareto';
import EmployeeConfiguration from './workload/employee-configuration';
import Workload from './workload/employee-workload';
import { getCalculated } from '../../helper/activities';
import { calcExpectationData, defaultExpectationData } from '../../helper/expectations';
import Error from '../error';
import { useClient } from '../../providers/client-provider';
import { useDepartment } from '../../providers/department-provider';
import { useEvaluation } from '../../providers/evaluation-provider';
import { isAdmin } from '../../helper/oauth2';
import { useAuth } from '../../providers/auth-provider';
import SpinnerIcon from '../spinner-icon';
import ValidationIconWrapper from '../../helper/validationIconWrapper';

function getToday() {
  return new Date().toISOString().split('T')[0];
}

const defaultEmployeeConfig = {
  daysPerFortnight: 0,
  hoursPerFortnight: 0,
  breakHoursPerDay: 0,
  availableFullTimeEmployees: 0,
  employeeAbsenceFactor: 0,
};

function TabHeader(props) {
  const { children } = props;
  return (
    <Nav.Item>
      <Nav.Link {...props}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
}

function Warning({
  show, title, body, cancel, onCancel, confirm, onConfirm,
}) {
  return (
    <Modal show={!!show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancel}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirm}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditEvaluation() {
  const { evaluationId } = useParams();
  const { setEvaluation } = useEvaluation();
  const { currentUser } = useAuth();

  const { client } = useClient();
  const { department } = useDepartment();
  const [currentEvaluation, setCurrentEvaluation] = useState(null);
  const [validated, setValidated] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [PDFBusy, setPDFBusy] = useState(false);
  const [libraryImporting, setLibraryImporting] = useState(false);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [warning, setWarning] = useState(null);
  // Form values
  const [name, setName] = useState('');
  const nameChanged = useCallback((newVal) => {
    setName(newVal);
    setDirty(true);
  }, []);

  const [evaluatedOn, setEvaluatedOn] = useState(getToday());
  const evaluatedOnChanged = useCallback((newVal) => {
    setEvaluatedOn(newVal);
    setDirty(true);
  }, []);

  const [manager, setManager] = useState('');
  const managerChanged = useCallback((newVal) => {
    setManager(newVal);
    setDirty(true);
  }, []);

  const [estimatedStationFillTime, setEstimatedStationFillTime] = useState(0);
  const estimatedStationFillTimeChanged = useCallback((newVal) => {
    setEstimatedStationFillTime(newVal);
    setDirty(true);
  }, []);

  const [expectedStationFillTime, setExpectedStationFillTime] = useState(0);
  const expectedStationFillTimeChanged = useCallback((newVal) => {
    setExpectedStationFillTime(newVal);
    setDirty(true);
  }, []);

  const [employeeConfiguration, setEmployeeConfiguration] = useState(null);
  const employeeConfigurationChanged = useCallback((newVal) => {
    setEmployeeConfiguration(newVal);
    setDirty(true);
  }, []);

  // persisted collections
  const [activityGroups, setActivityGroups] = useState([]);
  const activityGroupsChanged = useCallback((newVal) => {
    setActivityGroups(newVal);
    setDirty(true);
  }, []);

  const [observationInstructions, setObservationInstructions] = useState([]);
  const observationInstructionsChanged = useCallback((newVal) => {
    setObservationInstructions(newVal);
    setDirty(true);
  }, []);

  const [observations, setObservations] = useState([]);
  const observationsChanged = useCallback((newVal) => {
    setObservations(newVal);
    setDirty(true);
  }, []);

  //  calculated collections
  const [expectationData, setExpectationData] = useState(defaultExpectationData);

  // Automatically store WIP data in localstorage, in case of accidental browser navigation.
  const [workingData, setWorkingData] = useState(null);
  useEffect(() => {
    const newData = {
      department: name,
      evaluatedOn,
      manager,
      estimatedStationFillTime,
      expectedStationFillTime,
      activityGroups,
      observationInstructions,
      observations,
      employeeConfiguration,
    };
    setWorkingData(newData);
  }, [
    name,
    evaluatedOn,
    manager,
    estimatedStationFillTime,
    expectedStationFillTime,
    activityGroups,
    observationInstructions,
    observations,
    employeeConfiguration,
  ]);

  useEffect(() => {
    if (dirty) {
      localStorage.setItem(evaluationId, JSON.stringify(workingData));
    }
  }, [evaluationId, dirty, workingData]);

  const initForm = (evaluationData) => {
    setName(evaluationData.department);
    setEvaluatedOn(evaluationData.evaluatedOn);
    setManager(evaluationData.manager);

    setEstimatedStationFillTime(evaluationData.estimatedStationFillTime || 0);
    setExpectedStationFillTime(evaluationData.expectedStationFillTime || 0);

    setActivityGroups(evaluationData.activityGroups);
    setObservationInstructions(evaluationData.observationInstructions);
    setObservations(evaluationData.observations);
    setEmployeeConfiguration({ ...defaultEmployeeConfig, ...evaluationData.employeeConfiguration });

    setValidated(false);
  };

  const loadEvaluation = useCallback(async (id, wipData) => {
    const abortController = new AbortController();
    try {
      const evaluation = await WorkloadAPI.getEvaluation(id, abortController.signal);
      // Set context for other components (eg breadcrumbs)
      setEvaluation(evaluation);
      setCurrentEvaluation(evaluation);
      initForm(wipData || evaluation.data);
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error(err);
        setError(err);
      }
    }
    return () => { abortController.abort(); };
  }, [setEvaluation]);

  const revertData = () => {
    setWarning({
      title: 'Revert changes',
      body: 'You will lose any unsaved changes. Are you sure?',
      cancel: 'Keep my changes',
      onCancel: () => setWarning(null),
      confirm: 'Revert',
      onConfirm: () => {
        setSaving(true);
        localStorage.removeItem(evaluationId);
        loadEvaluation(evaluationId).then(() => setSaving(false));
        setDirty(false);
        setWarning(null);
      },
    });
  };

  useEffect(() => {
    const activityIds = activityGroups
      .reduce((ids, group) => [...ids, ...group.activities.map((a) => a.id)], []);
    setObservationInstructions((prev) => prev
      .filter((obs) => activityIds.includes(obs.activityId)));
    setObservations((prev) => prev.filter((obs) => activityIds.includes(obs.activityId)));
  }, [activityGroups]);

  useEffect(() => {
    if (activityGroups && observations) {
      setExpectationData(calcExpectationData(activityGroups, observations));
    } else {
      setExpectationData(defaultExpectationData);
    }
  }, [activityGroups, observations]);

  const showWIPDialog = useCallback((id, wipData) => {
    setWarning({
      title: 'Unsaved changes',
      body: 'It looks like you made some changes that have not been saved. Would you like to keep them?',
      cancel: 'Discard them',
      onCancel: () => {
        localStorage.removeItem(id);
        loadEvaluation(id);
        setWarning(null);
        setDirty(false);
      },
      confirm: 'Keep them',
      onConfirm: () => {
        loadEvaluation(id, wipData);
        setWarning(null);
        setDirty(true);
      },
    });
  }, [loadEvaluation]);

  useEffect(() => {
    const wipData = localStorage.getItem(evaluationId);
    if (wipData) {
      return showWIPDialog(evaluationId, JSON.parse(wipData));
    }
    return loadEvaluation(evaluationId);
  }, [showWIPDialog, loadEvaluation, evaluationId]);

  const getData = useCallback(() => ({
    department: name,
    evaluatedOn,
    manager,
    estimatedStationFillTime,
    expectedStationFillTime,
    activityGroups,
    observationInstructions,
    observations,
    employeeConfiguration,
    summary: {
      expectedWeeklyWorkload: expectationData.totals.weeklyExpectation,
    },
  }), [
    name,
    evaluatedOn,
    manager,
    estimatedStationFillTime,
    expectedStationFillTime,
    activityGroups,
    observationInstructions,
    observations,
    employeeConfiguration,
    expectationData,
  ]);

  const saveCopy = useCallback(() => {
    if (formRef.current.checkValidity()) {
      const data = getData();

      setSaving(true);
      WorkloadAPI.copyEvaluation(evaluationId, data).then((result) => {
        toastService.toast({
          type: 'success',
          message: 'The evaluation was copied successfully.',
        });
        setValidated(false);
        setDirty(false);
        localStorage.removeItem(evaluationId);
        navigate(`/workspace/client/${client.id}/department/${department.id}/evaluation/${result.id}`);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to copy the evaluation.',
        });
      }).then(() => setSaving(false));
    }
  }, [getData, navigate, client, department, evaluationId]);

  const handleSubmit = useCallback((event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity()) {
      const data = getData();

      setSaving(true);
      WorkloadAPI.updateEvaluation(evaluationId, data).then((result) => {
        toastService.toast({
          type: 'success',
          message: 'The evaluation was saved successfully.',
        });
        setValidated(false);
        setDirty(false);
        localStorage.removeItem(evaluationId);
        loadEvaluation(result.id);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to save the evaluation.',
        });
      }).then(() => setSaving(false));
    }
  }, [getData, loadEvaluation, evaluationId]);

  const getPDF = useCallback(() => {
    setPDFBusy(true);
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    WorkloadAPI.getEvaluationPDF(evaluationId, timeZone).then(
      () => { },
      (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to retrieve the PDF.',
        });
      },
    ).finally(() => setPDFBusy(false));
  }, [evaluationId]);

  const importAsBlueprint = useCallback(() => {
    setLibraryImporting(true);
    WorkloadAPI.importBlueprint(evaluationId)
      .then(() => {
        toastService.toast({
          type: 'success',
          message: 'The blueprint was created successfully',
        });
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'an error occurred while trying to create the blueprint',
        });
      })
      .then(() => setLibraryImporting(false));
  }, [evaluationId]);

  if (error) {
    return <Error error={error} />;
  }

  function getEditTabs() {
    const weeklyEstimate = activityGroups.reduce((total, group) => total + group.activities
      .reduce((sum, current) => sum + getCalculated(current).weeklyEstimatedSeconds, 0), 0);

    return (
      <div className="h-100 d-flex flex-column">
        <Tab.Container defaultActiveKey="activities" activeKey={activeTab} onSelect={(k) => setActiveTab(k)} unmountOnExit>
          <Nav variant="tabs" className="flex-row" fill>
            <TabHeader eventKey="employeeConfig">Employee Config</TabHeader>
            <TabHeader eventKey="activities">Activities</TabHeader>
            <TabHeader eventKey="workloadEstimate">Estimated workload</TabHeader>
            <TabHeader eventKey="paretos">Pareto Analysis</TabHeader>
            <TabHeader eventKey="instructions">Observation plan</TabHeader>
            <TabHeader eventKey="observations">Observations</TabHeader>
            <TabHeader eventKey="expectations">Expectations</TabHeader>
            <TabHeader eventKey="workloadExpectation">Expected workload</TabHeader>
          </Nav>
          <Tab.Content className="h-100">
            <Tab.Pane eventKey="employeeConfig" className="h-100 pt-3">
              <EmployeeConfiguration
                value={employeeConfiguration}
                onChange={employeeConfigurationChanged}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="activities" className="h-100">
              <ActivityGroupsTable value={activityGroups} onChange={activityGroupsChanged} />
            </Tab.Pane>
            <Tab.Pane eventKey="workloadEstimate" className="h-100 pt-3">
              <Workload
                weeklySeconds={weeklyEstimate}
                employeeConfig={employeeConfiguration}
                stationFillTime={estimatedStationFillTime}
                stationFillTimeChanged={estimatedStationFillTimeChanged}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="paretos" className="h-100">
              <ParetoTable
                activityData={activityGroups}
                onCreateObservationPlan={(newInstructions) => {
                  setObservationInstructions(newInstructions);
                  setActiveTab('instructions');
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="instructions" className="h-100">
              <InstructionsTable
                value={observationInstructions}
                onChange={observationInstructionsChanged}
                activityGroups={activityGroups}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="observations" className="h-100">
              <ObservationsTable
                value={observations}
                onChange={observationsChanged}
                activityGroups={activityGroups}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="expectations" className="h-100">
              <ExpectationsTable
                value={activityGroups}
                onChange={activityGroupsChanged}
                expectationData={expectationData}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="workloadExpectation" className="h-100 pt-3">
              <Workload
                weeklySeconds={expectationData.totals.weeklyExpectation}
                employeeConfig={employeeConfiguration}
                stationFillTime={expectedStationFillTime}
                stationFillTimeChanged={expectedStationFillTimeChanged}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  }

  function getForm() {
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
        <fieldset disabled={saving}>
          <Stack direction="horizontal" gap={3}>
            <h2 className="me-auto" style={{ fontStyle: dirty ? 'italic' : null }}>
              {name}
              {' '}
              {evaluatedOn}
              {' '}
              v
              {currentEvaluation.version + 1}
              {dirty ? '*' : ''}
            </h2>
            {saving
              && (
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Saving...</span>
              </Spinner>
              )}
            <Button variant="secondary" type="button" onClick={getPDF} disabled={dirty || PDFBusy}>
              {PDFBusy
                ? (
                  <Spinner className="me-2" animation="border" role="status" variant="info" size="sm">
                    <span className="visually-hidden">Saving...</span>
                  </Spinner>
                )
                : <FileEarmarkPdf className="me-2" />}
              <span>Create PDF</span>
            </Button>
            <Button variant="primary" type="button" onClick={revertData}>
              <CloudDownload className="me-2" />
              Reload
            </Button>
            <Button variant="secondary" type="button" onClick={saveCopy}>
              <Stickies className="me-2" />
              Save copy
            </Button>
            <Button variant="primary" type="submit" disabled={!dirty}>
              <CloudUpload className="me-2" />
              Save current
            </Button>
            {isAdmin(currentUser) && (
            <Button variant="secondary" type="button" onClick={importAsBlueprint} disabled={dirty || libraryImporting}>
              {libraryImporting ? <SpinnerIcon className="me-2" /> : <Safe2 className="me-2" />}
              Copy to library
            </Button>
            )}
          </Stack>
          <Row className="mb-3" xl="6">
            <Col>
              <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <ValidationIconWrapper>
                  <Form.Control
                    type="string"
                    placeholder="Department name"
                    required
                    value={name}
                    onChange={(e) => nameChanged(e.target.value)}
                  />
                </ValidationIconWrapper>
                <Form.Control.Feedback type="invalid">
                  Please enter a department.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formManager">
                <Form.Label>Manager</Form.Label>
                <ValidationIconWrapper>
                  <Form.Control
                    type="string"
                    placeholder="Manager name"
                    value={manager}
                    onChange={(e) => managerChanged(e.target.value)}
                  />
                </ValidationIconWrapper>
                <Form.Control.Feedback type="invalid">
                  Please enter a manager.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formEvaluatedOn">
                <Form.Label>Evaluated on</Form.Label>
                <ValidationIconWrapper>
                  <Form.Control
                    type="date"
                    required
                    value={evaluatedOn}
                    onChange={(e) => evaluatedOnChanged(e.target.value)}
                  />
                </ValidationIconWrapper>
                <Form.Control.Feedback type="invalid">
                  Please pick a date.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Col className="h-100">
            {getEditTabs()}
          </Col>
        </fieldset>
      </Form>
    );
  }

  return (
    <Row>
      {!!warning && (
      <Warning
        show={!!warning}
        title={warning.title}
        body={warning.body}
        confirm={warning.confirm}
        onConfirm={warning.onConfirm}
        cancel={warning.cancel}
        onCancel={warning.onCancel}
      />
      )}
      {currentEvaluation ? getForm()
        : <Loading />}
    </Row>
  );
}

export default EditEvaluation;
