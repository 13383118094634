import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

function getActivityById(activities, id) {
  return activities.find((a) => a.id === id);
}

export default function EditVolumeLink({ activities, value, onChange }) {
  const { id, ratio } = value || {
    id: null,
    ratio: 1,
  };

  const activity = getActivityById(activities, id);

  const emitChange = useCallback((newValue) => {
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [onChange]);

  const onRatioChange = (e) => {
    emitChange(value?.id ? {
      ratio: Number.parseFloat(e.target.value),
      id: value.id,
    } : null);
  };

  const onActivityChange = (e) => {
    const activityId = e.target.value;
    emitChange(activityId ? {
      ratio: value?.ratio || 1,
      id: activityId,
    } : null);
  };

  return (
    <Container>
      <Form>
        <Row>
          <Col>
            <Form.Label>
              Based on
            </Form.Label>
            <Form.Select value={activity?.id} onChange={onActivityChange}>
              <option value="">None</option>
              {activities.map((a) => <option key={a.id} value={a.id}>{a.name}</option>)}
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>
              Volume ratio
            </Form.Label>
            <Form.Control type="number" value={ratio} onChange={onRatioChange} />
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
