import { CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons';

export default function ValidationIconWrapper({ children }) {
  return (
    <>
      {children}
      <div className="validation-icon-wrapper">
        <CheckCircleFill size="0.8rem" className="form-control-icon if-valid" />
        <ExclamationCircleFill size="0.8rem" className="form-control-icon if-invalid" />
      </div>
    </>
  );
}
