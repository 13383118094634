import { useEffect, useState } from 'react';
import {
  Col, Container, Form, InputGroup, Row,
} from 'react-bootstrap';
import { getCalculated } from '../../../helper/employeeConfiguration';
import { roundEmployee, roundHour, roundPercentageDetailed } from '../../../helper/format';
import { DurationCell } from '../../../helper/table';
import ValidationIconWrapper from '../../../helper/validationIconWrapper';

function Workload({
  weeklySeconds,
  employeeConfig,
  stationFillTime,
  stationFillTimeChanged,
}) {
  const [calculatedValues, setCalculatedValues] = useState(employeeConfig
    ? getCalculated(employeeConfig)
    : { breakHoursPerFortnight: 0, breakRatio: 0 });

  const weeklySecs = weeklySeconds || 0;
  const hoursPerFortnight = employeeConfig?.hoursPerFortnight || 0;
  const employeeAbsenceFactor = employeeConfig?.employeeAbsenceFactor || 0;
  const availableFullTimeEmployees = employeeConfig?.availableFullTimeEmployees || 0;
  const [weeklyWorkSeconds, setWeeklyWorkSeconds] = useState(0);
  const [weeklyBreakSeconds, setWeeklyBreakSeconds] = useState(0);
  const [totalWeeklySeconds, setTotalWeeklySeconds] = useState(0);
  const [requiredSeconds, setRequiredSeconds] = useState(0);
  const [requiredEmployees, setRequiredEmployees] = useState(0);

  useEffect(() => {
    setCalculatedValues(employeeConfig
      ? getCalculated(employeeConfig)
      : { breakHoursPerFortnight: 0, breakRatio: 0 });
  }, [employeeConfig]);

  useEffect(() => {
    setWeeklyWorkSeconds(weeklySecs + stationFillTime);
  }, [weeklySecs, stationFillTime]);

  useEffect(() => {
    setWeeklyBreakSeconds(Math.round(weeklyWorkSeconds * calculatedValues.breakRatio));
  }, [weeklyWorkSeconds, calculatedValues]);

  useEffect(() => {
    setTotalWeeklySeconds(weeklyWorkSeconds + weeklyBreakSeconds);
  }, [weeklyWorkSeconds, weeklyBreakSeconds]);

  useEffect(() => {
    setRequiredSeconds((1 + employeeAbsenceFactor) * totalWeeklySeconds);
  }, [employeeAbsenceFactor, totalWeeklySeconds]);

  useEffect(() => {
    const weeklyHours = hoursPerFortnight / 2;
    setRequiredEmployees(weeklyHours ? (requiredSeconds / 3600) / weeklyHours : 0);
  }, [requiredSeconds, hoursPerFortnight]);

  return (
    <Container>
      <Row className="mb-3" xl="5">
        <Col>
          Weekly workload
        </Col>
        <Col />
        <Col>
          <DurationCell
            prefixes={<InputGroup.Text>=</InputGroup.Text>}
            value={weeklySecs}
            readOnly
            disabled
          />
        </Col>
      </Row>
      <Row className="mb-3" xl="5">
        <Col>
          Time at station fill positions
        </Col>
        <Col>
          <DurationCell
            prefixes={<InputGroup.Text>+</InputGroup.Text>}
            value={stationFillTime}
            onChange={stationFillTimeChanged}
          />
        </Col>
        <Col>
          <DurationCell
            prefixes={<InputGroup.Text>=</InputGroup.Text>}
            value={weeklyWorkSeconds}
            readOnly
            disabled
          />
        </Col>
      </Row>
      <Row className="mb-3" xl="5">
        <Col>
          <Form.Group>
            <Form.Label>Breaks</Form.Label>
            <InputGroup>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundPercentageDetailed(calculatedValues.breakRatio * 100)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Total break time</Form.Label>
            <DurationCell
              prefixes={<InputGroup.Text>+</InputGroup.Text>}
              value={weeklyBreakSeconds}
              readOnly
              disabled
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Subtotal</Form.Label>
            <DurationCell
              prefixes={<InputGroup.Text>=</InputGroup.Text>}
              value={totalWeeklySeconds}
              readOnly
              disabled
            />
            <InputGroup className="workload-important">
              <InputGroup.Text>=</InputGroup.Text>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundHour(totalWeeklySeconds / 3600)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>h</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3" xl="5">
        <Col>
          Required weekly time
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Absences</Form.Label>
            <InputGroup>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundPercentageDetailed(employeeAbsenceFactor * 100)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Subtotal</Form.Label>
            <InputGroup>
              <InputGroup.Text>=</InputGroup.Text>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundHour(requiredSeconds / 3600)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>h</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3" xl="5">
        <Col>
          Employees
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Available time</Form.Label>
            <InputGroup>
              <ValidationIconWrapper>
                <Form.Control type="number" value={hoursPerFortnight / 2} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>h / FTE</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Required</Form.Label>
            <InputGroup>
              <InputGroup.Text>=</InputGroup.Text>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundEmployee(requiredEmployees)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>FTE</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3" xl="5">
        <Col />
        <Col>
          <Form.Group>
            <Form.Label>Current</Form.Label>
            <InputGroup>
              <ValidationIconWrapper>
                <Form.Control type="number" value={availableFullTimeEmployees} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>FTE</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Difference</Form.Label>
            <InputGroup>
              <InputGroup.Text>=</InputGroup.Text>
              <ValidationIconWrapper>
                <Form.Control type="number" value={roundEmployee(availableFullTimeEmployees - requiredEmployees)} disabled className="text-end" />
              </ValidationIconWrapper>
              <InputGroup.Text>FTE</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}

export default Workload;
