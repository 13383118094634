import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack } from 'react-bootstrap';
import { CloudUpload } from 'react-bootstrap-icons';
import WorkloadAPI from '../../workload-api';
import toastService from '../../toast-service';
import { useDepartment } from '../../providers/department-provider';
import { useClient } from '../../providers/client-provider';
import ValidationIconWrapper from '../../helper/validationIconWrapper';

function getToday() {
  return new Date().toISOString().split('T')[0];
}

function EvaluationForm() {
  const [validated, setValidated] = useState(false);
  const { client } = useClient();
  const { department, setEvaluations } = useDepartment();
  // Form values
  const [name, setName] = useState('');
  const [evaluatedOn, setEvaluatedOn] = useState(getToday());
  const [manager, setManager] = useState('');
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    const form = event.target;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (form.checkValidity() && client) {
      const data = {
        department: name,
        evaluatedOn,
        manager,
      };
      setSaving(true);
      WorkloadAPI.createEvaluation(client.id, department.id, data).then((result) => {
        toastService.toast({
          type: 'success',
          message: 'The evaluation was created successfully.',
        });
        setEvaluations(null);
        navigate(`../evaluation/${result.id}`);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to create the evaluation.',
        });
        setSaving(false);
      });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="pt-3">
      <fieldset disabled={saving} className="flex-fill-limit d-flex flex-column">
        <Container>
          <Row>
            <h3>New evaluation</h3>
          </Row>
          <Row xl="6">
            <Form.Group as={Col} className="mb-3" controlId="formDepartment">
              <Form.Label>Department</Form.Label>
              <ValidationIconWrapper>
                <Form.Control
                  type="string"
                  placeholder="Department name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </ValidationIconWrapper>
              <Form.Control.Feedback type="invalid">
                Please enter a department.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formManager">
              <Form.Label>Manager</Form.Label>
              <ValidationIconWrapper>
                <Form.Control
                  type="string"
                  placeholder="Manager name"
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                />
              </ValidationIconWrapper>
              <Form.Control.Feedback type="invalid">
                Please enter a manager.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" controlId="formEvaluatedOn">
              <Form.Label>Evaluated on</Form.Label>
              <ValidationIconWrapper>
                <Form.Control
                  type="date"
                  required
                  value={evaluatedOn}
                  onChange={(e) => setEvaluatedOn(e.target.value)}
                />
              </ValidationIconWrapper>
              <Form.Control.Feedback type="invalid">
                Please pick a date.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="py-1" xl="6">
            <Col />
            <Col />
            <Col>
              <Stack direction="horizontal" gap={3}>
                <div className="ms-auto" />
                <Button variant="primary" type="submit">
                  {saving
                    ? (
                      <Spinner className="me-2" animation="border" role="status" variant="info" size="sm">
                        <span className="visually-hidden">Saving...</span>
                      </Spinner>
                    )
                    : <CloudUpload className="me-2" />}
                  Create
                </Button>
              </Stack>
            </Col>
          </Row>
        </Container>
      </fieldset>
    </Form>
  );
}

export default EvaluationForm;
