import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import { CloudUpload } from 'react-bootstrap-icons';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import toastService from '../../toast-service';
import WorkloadAPI from '../../workload-api';
import Error from '../error';
import Loading from '../loading';
import SpinnerIcon from '../spinner-icon';
import ValidationIconWrapper from '../../helper/validationIconWrapper';

const schema = Joi.object({
  name: Joi.string().required(),
});

function EditDepartmentForm() {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const {
    register, handleSubmit, setValue, formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });

  const { departmentId } = useParams();

  useEffect(() => {
    async function loadDepartment() {
      const client = await WorkloadAPI.getDepartment(departmentId);
      setValue('name', client.data.name, { shouldDirty: false });
      setLoading(false);
    }
    loadDepartment().then(() => {}, (reason) => setError(reason));
  }, [departmentId, setValue]);

  const updateDepartment = (data) => {
    setValidated(true);

    if (isValid) {
      setSaving(true);
      WorkloadAPI.updateDepartment(departmentId, data).then(() => {
        toastService.toast({
          type: 'success',
          message: 'The department was saved successfully.',
        });
        setValidated(false);
      }, (reason) => {
        console.error(reason);
        toastService.toast({
          type: 'danger',
          message: 'An error occurred while trying to save the department.',
        });
      }).then(() => setSaving(false));
    }
  };

  if (error) {
    return <Error error={error} />;
  }

  return (
    <>
      {loading && <Loading />}
      <Fade in={!loading}>
        <Form noValidate validated={validated} onSubmit={handleSubmit((d) => updateDepartment(d))} className="flex-fill-limit d-flex flex-column pt-3">
          <fieldset disabled={saving} className="flex-fill-limit d-flex flex-column">
            <Container className="flex-fill-limit d-flex flex-column">
              <Row className="py-1" xl={3}>
                <Col><h3>Department</h3></Col>
                <Col md="auto">
                  <Button variant="primary" type="submit" disabled={!(isDirty && isValid)}>
                    {saving ? <SpinnerIcon className="me-2" /> : <CloudUpload className="me-2" />}
                    <span>Save</span>
                  </Button>
                </Col>
              </Row>
              <Row xl={3}>
                <Form.Group as={Col} className="mb-3" controlId="formDepartmentName">
                  <Form.Label>Name</Form.Label>
                  <ValidationIconWrapper>
                    <Form.Control type="text" {...register('name')} isInvalid={!!errors.name} />
                  </ValidationIconWrapper>
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Container>
          </fieldset>
        </Form>
      </Fade>
    </>
  );
}

export default EditDepartmentForm;
